$defaultMarginTop: 10rem;
$defaultPadding: 0 2rem;

.kvNews {
  position: relative;

  &__bgImage {
    & > img {
      width: 100%;
      max-height: 100vh;
      object-fit: cover;
      filter: brightness(0.5);
    }
  }

  &__text {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);

    > h1 {
      width: max-content;
      text-align: center;
      color: #ffffff;
      font-size: 2.2rem;
      font-weight: bold;
      padding: 0 0 3px 5px;
      border-bottom: #ffffff 2px solid;
    }
  }
}

.newsDetail {
  padding: 0 5rem;
  max-width: 1024px;
  margin: 0 auto;

  @media screen and (max-width: 425px) {
    padding: 0rem;
    margin: 0 20px;
  }

  &__title {
    margin-bottom: 5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #8e8e8e;
    font-size: 2rem;
  }

  &__detail {
    margin-bottom: 3rem;

    &--contents {
      display: block;
      padding: 2rem 3rem;

      @media screen and (max-width: 425px) {
        padding: 1rem;
      }
    }

    h3 {
      font-weight: bold;
      margin-bottom: 1rem;
    }

    li {
      margin-bottom: 2rem;
      border-radius: 3px;
      background-color: #fafafa;
      box-shadow: 2px 2px 10px 0px rgb(0 0 0 / 40%);
      transition: 0.3s;

      &:hover {
        background-color: #dbdbdb;
        opacity: 0.7;
      }
    }
  }
}

.newsSingle {
  max-width: 800px;
  margin: 5rem auto;

  &__detail {
    line-height: 2.2rem;

    @media screen and (max-width: 425px) {
      padding: 0rem;
      margin: 0 20px;
    }

    &--title {
      font-weight: bold;
      font-size: 2rem;
      text-align: center;
      margin-bottom: 5rem;
    }

    // ブログテキストの設定
    // MEMO: 強制的にwidthを指定する
    div {
      max-width: 800px !important;
    }

    > h1 {
      margin-bottom: 1rem;
      padding-left: 0.7rem;
      font-size: 1.8rem;
      font-weight: bold;
      border-left: 8px solid #008a0e;
    }

    > h2 {
      margin-bottom: 0.8rem;
      padding-bottom: 0.8rem;
      font-size: 1.6rem;
      font-weight: bold;
      border-bottom: 1px solid #008a0e;
    }

    > h3 {
      font-size: 1.4rem;
      font-weight: bold;
    }

    > h4 {
      margin-bottom: 0.8rem;
      font-size: 1.4rem;
    }

    > h5 {
      font-weight: bold;
      font-size: 1.2rem;
    }

    > h6 {
      font-size: 1.2rem;
    }

    // MEMO: 画面幅を超えるのを防ぐため強制的に指定
    img {
      width: 100%;
      max-width: 800px !important;
      max-height: 100vh;
      object-fit: cover;
    }
  }
}
