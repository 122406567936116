.header {
  width: 100%;
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.header__inner {
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: inherit;
  position: relative;
}

/* ヘッダーのロゴ部分 */
.header__title {
  width: 80px;

  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    padding: 10px;
    background-color: #fff;
  }
}

@media screen and (min-width: 960px) {
  .header__title {
    width: 120px;
  }
}

.header__title img {
  display: block;
  max-width: 100px;
  width: 100%;
  height: 100%;
}

/* ヘッダーのナビ部分 */

.header__nav {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  transform: translateX(100%);
  background-color: #fff;
  transition: ease 0.4s;
}

@media screen and (min-width: 960px) {
  .header__nav {
    position: static;
    transform: initial;
    background-color: inherit;
    height: inherit;
    display: flex;
    justify-content: end;
    width: 50%;
  }
}

@media screen and (min-width: 960px) {
  .nav__items {
    width: 100%;
    display: flex;
    align-items: center;
    height: initial;
    justify-content: space-between;
  }
}

.nav-items {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
}

@media screen and (min-width: 960px) {
  .nav-items {
    position: inherit;
    top: 0;
    left: 0;
    transform: translate(0, 0);
  }
}

/* ナビのリンク */
.nav-items__item a {
  color: black;
  width: 100%;
  display: block;
  text-align: center;
  font-size: 20px;
  margin-bottom: 24px;
  color: black;
}

.nav-items__item:last-child a {
  margin-bottom: 0;
}

@media screen and (min-width: 960px) {
  .nav-items__item a {
    margin-bottom: 0;
  }
}

/* ハンバーガーメニュー */

.header__hamburger {
  width: 48px;
  height: 100%;
}

.hamburger {
  background-color: transparent;
  border-color: transparent;
  z-index: 9999;
}

@media screen and (min-width: 960px) {
  .hamburger {
    display: none;
  }
}

/* ハンバーガーメニューの線 */
.hamburger span {
  width: 100%;
  height: 1px;
  background-color: #000;
  position: relative;
  transition: ease 0.4s;
  display: block;
}

.hamburger span:nth-child(1) {
  top: 0;
}

.hamburger span:nth-child(2) {
  margin: 8px 0;
}

.hamburger span:nth-child(3) {
  top: 0;
}

/* ハンバーガーメニュークリック後のスタイル */
.header__nav.active {
  transform: translateX(0);
}

.hamburger.active span:nth-child(1) {
  top: 5px;
  transform: rotate(45deg);
}

.hamburger.active span:nth-child(2) {
  opacity: 0;
}

.hamburger.active span:nth-child(3) {
  top: -13px;
  transform: rotate(-45deg);
}

.black {
  color: #000 !important;
}

.border-black {
  border-color: #000 !important;
}

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  padding: 3rem 5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  font-size: 0.75rem;
  z-index: 9999;
  transition: 0.1s;

  @media screen and (max-width: 425px) {
    display: none;
  }

  &__logo {
    & > a {
      position: absolute;
      top: 0;
      left: 0;
      background-color: #ffffff;

      img {
        max-width: 100px;
        width: 100%;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 3rem;

    > li:last-child {
      > a {
        padding: 10px;
        border: #ffffff 1px solid;
        transition: 0.1s;
      }
    }
  }
}
