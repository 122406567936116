$defaultMarginTop: 10rem;
$defaultPadding: 10rem 2rem;
html {
  scroll-behavior: smooth;
}

.sp-only {
  display: none;

  @media screen and (max-width: 425px) {
    display: block;
  }
}

.pc-only {
  display: block;

  @media screen and (max-width: 425px) {
    display: none;
  }
}

.kvTop {
  position: relative;

  &__bgImage {
    & > img {
      width: 100%;
      object-fit: cover;
      filter: brightness(0.7);

      @media screen and (max-width: 425px) {
        min-height: 90vh;
      }
    }
  }

  &__text {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 425px) {
      top: 50%;
    }

    > h1 {
      width: max-content;
      text-align: center;
      color: #ffffff;
      font-size: 2.2rem;
      font-weight: bold;
      padding: 0 0 3px 5px;
      border-bottom: #ffffff 2px solid;

      @media screen and (max-width: 425px) {
        text-align: left;
        font-size: 28px;
        border: none;
        text-decoration: underline;
        line-height: 52px;
      }
    }
  }
}

.philosophy {
  margin-top: $defaultMarginTop;
  padding: $defaultPadding;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5rem;

    @media screen and (max-width: 425px) {
      display: block;
    }

    & > li:first-child {
      img {
        max-width: 400px;
        width: 100%;
        object-fit: cover;
      }
    }

    & > li:last-child {
      & > h2 {
        font-size: 1.6rem;
        font-weight: bold;
        margin-bottom: 1.2rem;

        @media screen and (max-width: 425px) {
          text-align: center;
          font-size: 24px;
          margin: 2rem 0;

          > span {
            padding-bottom: 0.3rem;
            border-bottom: #008a0e 2px solid;
          }
        }
      }
      & > p {
        line-height: 1.4rem;
        font-size: 1.2rem;
      }
    }
  }
}

.service {
  position: relative;
  margin-top: $defaultMarginTop;
  padding: $defaultPadding;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f2f2f2;
    z-index: -1;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5rem;

    @media screen and (max-width: 425px) {
      display: block;
    }

    & > li:first-child {
      img {
        max-width: 400px;
        width: 100%;
        object-fit: cover;
      }

      & > h2 {
        font-size: 1.6rem;
        font-weight: bold;
        margin-bottom: 1.2rem;

        @media screen and (max-width: 425px) {
          text-align: center;
          font-size: 24px;
          margin: 5rem 0;

          > span {
            padding-bottom: 0.3rem;
            border-bottom: #008a0e 2px solid;
          }
        }
      }
      & > p {
        line-height: 1.4rem;
        font-size: 1.2rem;
      }
    }

    &--button {
      margin-top: 3rem;

      @media screen and (max-width: 425px) {
        margin: 7rem auto 3rem;
        text-align: center;
      }

      & > a {
        padding: 1.5rem 2.5rem;
        border: 1px solid #5f5f5f;
        transition: 0.2s;

        &:hover {
          color: #f2f2f2;
          background-color: #5f5f5f;
        }
      }
    }

    & > li:last-child {
      img {
        max-width: 400px;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.news {
  margin-top: $defaultMarginTop;
  padding: $defaultPadding;

  &__title {
    text-align: center;
    font-size: 1.6rem;
    margin-bottom: 6rem;
    font-weight: bold;

    @media screen and (max-width: 425px) {
      margin-bottom: 3rem;
    }

    & > h2 {
      display: inline-block;
      border-bottom: 2px solid #008a0e;
    }
  }

  &__item {
    margin: 0 auto;
    max-width: 768px;
    padding: 3rem;
  }

  &__list {
    margin-bottom: 2.5rem;

    &--title {
      margin-bottom: 0.75rem;
      font-size: 1.2rem;

      @media screen and (max-width: 425px) {
        font-size: 1rem;

        > span {
          display: block;
        }
      }
    }

    &--description {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }

  &__button {
    text-align: center;

    & > a {
      padding: 1.5rem 2.5rem;
      border: 1px solid #5f5f5f;
      transition: 0.2s;

      &:hover {
        color: #f2f2f2;
        background-color: #5f5f5f;
      }
    }
  }
}

.recruit {
  margin-top: $defaultMarginTop;
  position: relative;
  min-height: 440px;

  &__bg {
    text-align: center;

    & > img {
      width: 100%;
      max-height: 580px;
      object-fit: cover;

      @media screen and (max-width: 425px) {
        max-width: 400px;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  &__item {
    position: absolute;
    top: 10rem;
    left: 10rem;
    width: 30%;
    max-width: 400px;

    @media screen and (max-width: 425px) {
      position: static;
      width: 100%;
      text-align: center;
      max-width: fit-content;
    }

    & > h2 {
      font-size: 1.6rem;
      margin-bottom: 2rem;
      font-weight: bold;

      @media screen and (max-width: 425px) {
        text-align: center;
        font-size: 24px;
        margin: 5rem auto;

        > span {
          padding-bottom: 0.3rem;
          border-bottom: #008a0e 2px solid;
        }
      }
    }

    & > p {
      font-size: 1.2rem;
      line-height: 1.4rem;

      @media screen and (max-width: 425px) {
        padding: 0 30px;
      }
    }

    &--button {
      margin-top: 5rem;

      & > a {
        padding: 1.5rem 2.5rem;
        border: 1px solid #5f5f5f;
        transition: 0.2s;

        &:hover {
          color: #f2f2f2;
          background-color: #5f5f5f;
        }
      }
    }
  }
}

.company {
  position: relative;
  padding: $defaultPadding;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f2f2f2;
    z-index: -1;

    @media screen and (max-width: 425px) {
      top: 20%;
    }
  }

  &__title {
    text-align: center;
    margin-bottom: 6rem;

    & > h2 {
      display: inline-block;
      border-bottom: 2px solid #008a0e;
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 3rem;
    }
  }

  &__description {
    display: grid;
    grid-template-columns: 200px 1fr;
    row-gap: 2rem;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 768px;
    line-height: 1.6rem;

    @media screen and (max-width: 425px) {
      grid-template-columns: 130px 1fr;
    }

    & > dt {
      padding-left: 80px;

      @media screen and (max-width: 425px) {
        padding-left: 20px;
      }
    }
  }
}

.map {
  & > iframe {
    min-height: 700px;
    width: 100%;
  }
}
