footer {
  display: flex;
  justify-content: space-between;
  background-color: #d9d9d9;
  padding: 100px 0;

  @media screen and (max-width: 425px) {
    display: block;
  }

  .logoFooter {
    text-align: center;
    width: 50%;
    padding: 100px;
    border-right: 1px solid #5e5e5e;

    img {
      max-width: 300px;
      width: 100%;
    }

    @media screen and (max-width: 425px) {
      width: 100%;
      text-align: center;
      padding: 10px;
      border: none;
      font-weight: bold;

      img {
        max-width: 100px;
        width: 100%;
      }
    }
  }
  .navFooter {
    width: 50%;
    padding: 100px;

    @media screen and (max-width: 425px) {
      padding: 10px;
      text-align: center;
      width: 100%;
    }

    &__item {
      & > li {
        display: block;
        margin-bottom: 2rem;
      }
    }
  }
}
